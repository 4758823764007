@import '~antd/dist/antd.css';
@import './framework7.min.css';

html,
body {
  font-size: 16px;
  overflow: auto;
}
form {
  margin-bottom: unset;
  padding-bottom: unset;
  border-bottom: unset;
}